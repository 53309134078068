.bloc-gallery {
  padding: 70px 0px 50px;
  text-align: center;
  background: $white;

  img {
    width: 100%;
    max-width: 100%;
    display: block;
  }

  .title2{
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    color: $colorAccent1;
  }

  .title3{
    padding: 0;
    color: $black;
    font-size: 36px;
    margin-bottom: 20px;
  }

  .main-text{
    color: $black;
    text-align: center;
    margin-bottom: 50px;
  }

  .grid-with-content {
    position: relative;
    background-color: $black;
    padding: 20px;
    transition: all 0.2s ease-in-out;

    img {
      opacity: 0;
    }

    .content {
      position: absolute;
      text-align: center;
      color: $white;
      top: 50%;
      left: 0;
      width: 100%;
      transform: translate(0,-50%);
      padding: 20px;
      
      a {
        font-size: 24px;
        text-align: center;
        letter-spacing: 0.05em;
        text-transform: uppercase;
        font-family: $fontTitle;
        font-weight: 400;
        line-height: 24px;
        max-width: 150px;
        display: block;
        margin: auto;
      }

      span {
        display: block;
        width: 45px;
        height: 45px;
        margin: auto;
        border: 3px solid $colorAccent1;
        border-radius: 50%;
        text-align: center;
        margin-top: 15px;
        transition: all 0.2s ease-in-out;

        i {
          font-size: 30px;
          font-weight: 400;
          color: #ffba00;
          margin-left: 1px;
          display: block;
          margin-top: -1px;
        }

      }

    }

    &:hover {
      background-color: #101010;

      span {
        background-color: $colorAccent1;

        i {
          color: $black;
        }
      }
    }

    @media (min-width: 992px) and (max-width: 1100px) {
      .content a {
        font-size: 18px;
      }
    }

  }

  @media all and (max-width: 1024px) {
    padding: 60px 0px;

    .title-section {
      padding: 0 20px; 
    }
    
    img {
      width: 100%;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .col.one-sixth {
      width: 33.3333%;
      float: left;
    }
  }

  @media all and (max-width: 767px) {
    .col.one-sixth {
      width: 50%;
      float: left;
    }

    .title3 {
      font-size: 32px;
    }
  }

  /*@media all and (max-width: 400px) {
    .col.one-sixth {
      width: 100%;
      float: none;
    }
  }*/

}