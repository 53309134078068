.bloc-statistics {
  background: $white;

  .content {
    padding: 75px 15px;
    border-right: 1px solid $white;
    background-color: $black;

    @media all and (max-width: 767px) {
      border-right: 0;
      border-bottom: 1px solid $white;
    }

    .title3 {
      padding: 0;
      font-size: 24px;
      font-weight: 400;
      text-transform: none;
      color: $white;
      text-align: center;
      min-height: 125px;

      span {
        display: block;
        margin-bottom: 10px;
        text-transform: uppercase;
        color: $colorAccent1;
        font-size: 64px;
        font-weight: 700;

        &.counter-with-info {
          color: $colorAccent1;

          & .counter {
            display: inline-block;
            margin: 0;
            margin-right: 5px;
          }

        }

      }

    }

  }

  .col:last-child .content {
    border: 0;
  }

  @media (min-width: 465px) and (max-width: 991px) {
    .col.one-fourth {
      width: 50%;
      float: left;

      .content {
        border-bottom: 1px solid $white;
        border-right: 1px solid $white;

      }

      &:nth-child(even) .content {
        border-right: 0;
      }

    }

  }

  @media (min-width: 385px) and (max-width: 464px) {
    .col.one-fourth {
      width: 50%;
      float: left;

      .content {
        border-bottom: 1px solid $white;
        border-right: 1px solid $white;
      }

      &:nth-child(even) .content {
        border-right: 0;
      }

      .title3 {
        font-size: 20px;
      }

    }

  }

}
