.init-grid{
  position: relative;

  .row{
    position: relative;
    @extend %clearfix;

    &.max-width{
      max-width: 1200px;
      margin: 0 auto;
    }

  }

  .col{
    position: relative;
    width: 100%;
    &.half{
      float: left;
      width: 50%;
    }
    &.one-third {
      float: left;
      width: 33.3333%;
    }
    &.one-fourth{
      float: left;
      width: 25%;
    }
    &.one-fifth {
      float: left;
      width: 20%;
    }
    &.one-sixth {
      float: left;
      width: 16.6666%;
    }
    &.one-eighth {
      float: left;
      width: 12.5%;
    }
  }

  @media all and (max-width: 1024px) {
    .col{
      &.half{
        float: none;
        width: 100%;
      }
    }
  }
  @media all and (max-width: 767px) {
    .col{
      &.half{
        float: none;
        width: 100%;
      }
      &.one-third {
        float: none;
        width: 100%;
      }
      &.one-fourth{
        float: none;
        width: 100%;
      }
      &.one-fifth {
        float: none;
        width: 100%;
      }
      &.one-sixth {
        float: none;
        width: 100%;
      }
    }
  }
}
