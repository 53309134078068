
/*
 *    Colors
 *    ------
 */


$black : #000;
$white : #fff;

$grey : #777777;
$gray : #777777;

$light : #f6f6f6;
$dark   : #373737;
$darker : #373737;

$colorAccent1 : #ffba00;
$colorAccent2 : #000;
$colorAccent3 : #eaeaea;
$colorAccent4 : #373737;




/*
 *    Fonts
 *    -----
 */

$fontTitle          : 'Roboto Condensed', sans-serif;
$fontTitleBold      : 'Roboto Condensed', sans-serif;
$fontText           : 'Roboto', sans-serif;




/*
 *    Breakpoints
 *    -----------
 */

