.bloc-contact {
  padding: 60px 0px;
  text-align: center;
  background: $white;
  position: relative;

  .title2{
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    color: $colorAccent1;
  }

  .title3{
    padding: 0;
    color: $black;
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    color: $black;
    text-align: center;
    width: 100%;
    max-width: 1000px;
    margin: auto;
    margin-bottom: 20px;

    &.main-text {
      font-size: 15px;
    }

    &.contact-info {
      font-family: $fontTitle;
      font-weight: 400;
      font-size: 18px;

      span {
        display: block;
        margin-top: 20px;
        color: $colorAccent1;
      }

      a{
        color: $colorAccent1;
      }

    }

  }

  .employee-bloc {
    margin-top: 30px;
    padding: 0 10px;
    min-height: 325px;

    @media (max-width: 767px) {
      min-height: 0;
    }

    @media (min-width: 768px) and (max-width: 884px) {
      min-height: 318px;
    }

    @media (min-width: 885px) and (max-width: 991px) {
      min-height: 300px;
    }

    img {
      width: 175px;
      max-width: 100%;
      border-radius:50%;
    }

    p {
      margin-bottom: 0;
      line-height: 20px;

      &.name {
        font-weight: 600;
      }
      &.type {
        line-height: 18px;
        font-size: 15px;
        padding-bottom: 10px;
        margin-bottom: 6px;
        position: relative;
        &:before {
          content: '';
          width: 14px;
          height: 1px;
          background-color: $colorAccent1;
          position: absolute;
          left: 50%;
          bottom: 0;
          margin-left: -7px;
        }
         @media (min-width: 768px) and (max-width: 991px) {
          font-size: 14px;
        }

      }
      &.phone {
        font-size: 14px;
      }

    }

    a {
      color: $colorAccent1;
      font-size: 14px;
      margin-top: -3px;
      display: block;

      &:hover {
        text-decoration: underline;
      }

    }

  }

  @media all and (max-width: 1024px) {
    padding: 60px 20px;

    .title-section {
      padding: 0 20px;
    }
  }

  @media (min-width: 992px) and (max-width: 1099px) {
    .col.one-fifth {
      width: 25%;
      float: left;
    }
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .col.one-fifth {
      width: 33.3333%;
      float: left;
    }
  }

  @media all and (max-width: 767px) {
    .col.one-fifth {
      width: 50%;
      float: left;
    }

    .title3{
      font-size: 28px;
      line-height: 34px;
    }
  }

  @media all and (max-width: 400px) {
    .col.one-fifth {
      width: 200px;
      max-width: 100%;
      margin: auto;
      margin-top: 30px;
      float: none;
    }
    .content {
      border-left: 0;
      border-right: 0;
    }
  }

}