.bloc-about {
  padding: 6vw 50px;
  text-align: center;
  background: $white;

  .half {
    padding: 0 15px;
  }

  img {
    max-width: 100%;

    @media all and (max-width: 991px) {
      margin-bottom: 20px;
      width: 600px;
    }

  }

  .title2{
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    color: $colorAccent1;
  }

  .title3{
    padding: 0;
    color: $black;
    font-size: 36px;
    margin-bottom: 50px;
  }

  .main-text{
    color: $black;
    text-align: left;
    margin-bottom: 20px;

    @media (min-width: 768px) and (max-width: 991px) {
      width: 600px;
      max-width: 100%;
      margin: auto;
      margin-bottom: 20px;
      text-align: center;
    }

  }


  @media all and (max-width: 1024px) {
    padding: 60px 20px;

    .title3 {
      font-size: 32px;
    }
  }

  @media (min-width: 992px) {
      .col.half {
        width: 50%;
        float: left;
      }
  }

}