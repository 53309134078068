
/**
 * Import Vendor for components dependencies
 *
 */
@import '_themes/css/vendors/parsley';
@import '_themes/css/vendors/sweetalert';
@import '_themes/css/vendors/flickity';

/**
 * Import website Framwork base
 *
 *    Settings
 *    --------
 *      Colors pallet and Fonts
 *
 *    Mixins
 *    --------
 *      Usefull CSS functions to help develop
 *
 */
@import '_themes/css/settings';
@import '_themes/css/mixins';

/**
 * Reset
 *
 */
@import '_themes/css/base/global';
@import '_themes/css/base/normalize';

/**
 * Small Components
 *
 */
@import '_themes/css/grid';
@import '_themes/css/icon-fonts';
@import '_themes/css/btns';
@import '_themes/css/forms';
@import '_themes/css/typography';




/**
 * Import Component's core modules
 *
 */
@import '_components/main-nav-1/assets/css/main-nav-1';
@import '_components/hero-1/assets/css/hero-1';
@import '_components/bloc-about-1/assets/css/bloc-about-1';
@import '_components/bloc-statistics-1/assets/css/bloc-statistics-1';
@import '_components/bloc-gallery-1/assets/css/bloc-gallery-1';
@import '_components/bloc-gallery-2/assets/css/bloc-gallery-2';
@import '_components/bloc-cta-footer-1/assets/css/bloc-cta-footer-1';
@import '_components/bloc-location-1/assets/css/bloc-location-1';
@import '_components/bloc-event-1/assets/css/bloc-event-1';
@import '_components/bloc-contact-1/assets/css/bloc-contact-1';
@import '_components/footer-nav-1/assets/css/footer-nav-1';

