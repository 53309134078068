.hero {
  position: relative;

  .secondary-nav {
    position: absolute;
    top: 0;
    left: 0;

    width: 100%;

    padding: 15px 60px;

    color: $colorAccent1;

    @extend %clearfix;

    z-index: 15;

    transition: all 0.2s ease-in-out;

    .right {
      float: right;
      padding-top: 10px;

      @media all and (max-width: 767px) {
        float: none;
        text-align: center;
      }
    }

    a {
      margin-left: 20px;
      text-transform: uppercase;
      font-weight: 400;
      font-size: 16px;
      font-family: $fontTitle;
      color: $white;
      letter-spacing: 0.05em;
      position: relative;
      display: inline-block;
      transition: all 0.2s ease-in-out;

      &:hover {
        color: $colorAccent1;
      }

      &.ico-nav {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        border: 2px solid $colorAccent1;
        text-align: center;
        margin-left: 10px;

        i {
          font-size: 14px;
          color: $colorAccent1;
          line-height: 27px;

        }

        &:hover {
          background-color: $colorAccent1;

          i {
            color: $black;
          }
        }

      }

      @media all and (max-width: 767px) {
        font-size: 14px;
        margin: 0;
        padding: 0 5px 10px;

        &.ico-nav {
          position: absolute;
          top: -45px;
          left: 50%;
          margin-left: -45px;

          &:last-child {
            margin-left: -5px;
          }

        }

      }

      @media (min-width: 768px) and (max-width: 991px) {
        font-size: 14px;
      }

    }

    @media (max-width: 767px) {
      position: fixed;
      top: initial;
      left: initial;
      bottom: 0;
      right: 0;
      transform: translate( 100%, 0);
      width: 45%;
      min-width: 375px;
      height: auto;
      z-index: 999;
      transition: all ease 0.33s;
      text-align: left;
      border-top: 2px solid $white;
      padding: 10px 0px;

      &.active{
        transform: translate( 0, 0);
      }

    }

    @media (max-width: 400px) {
      width: 100%;
      min-width: inherit;
    }
  }

  img {
    position: relative;
    display: block;
    width: 100%;
    height: auto;

    &.mobile {
      display: none;
    }

  }

  .content {
    position: absolute;
    top: 50%;
    left: 0;
    transform: translate(0, -50%);

    width: 100%;
    height: auto;

    padding-left: 20px;
    padding-right: 20px;

    z-index: 2;

    text-align: center;

    .logo {
      width: 200px;
      max-width: 100%;
      margin: auto;
      margin-bottom: 40px;
    }

    .title1 {
      font-size: 21px;
      text-transform: none;
      font-weight: 400;
      margin: 0 auto;
      max-width: 1000px;
      color: $white;
      margin-bottom: 40px;
      padding-bottom: 0;
    }

    .title2 {
      font-size: 64px;
      color: $white;
      font-weight: 500;
      padding-bottom: 0;
      margin-bottom: 25px;
    }

    .site-btn {
      background-color: $colorAccent1;
      border-color: $colorAccent1;
      color: $black;

      &:hover {
        background-color: rgba(0,0,0,.75);
        border-color: #ffba00;
        color: #ffba00;
      }

    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 0 60px;
    }

  }

  @media all and (max-width: 767px) {
    
    .content {
      margin-top: -33px;

      .logo {
        width: 150px;
        margin-bottom: 25px;
      }

      .title1 {
        font-size: 18px;
        margin-bottom: 30px;
      }

      .title2 {
        font-size: 52px;
        margin-bottom: 15px;
      }

    }

  }

}



.hero.fullheight {
  min-height: 100vh;
  background: url('../images/hero.jpg') $light center center no-repeat;
  background-size: cover;
}