
.main-footer{
  position: relative;
  padding: 20px 50px;

  background: $light;

  @extend %clearfix;

  .main-text {
    color: $black;
    font-size: 14px;

    &.footer-nav {
      text-transform: uppercase;
      font-family: $fontTitle;


      a {
        margin-left: 20px;
        position: relative;
        display: inline-block;
        transition: all 0.2s ease-in-out;

        &:hover {
          color: $colorAccent1;
        }

        &.ico-nav {
          width: 28px;
          height: 28px;
          border-radius: 50%;
          border: 2px solid $colorAccent1;
          text-align: center;
          margin-left: 10px;
          margin-top: -2px;

          i {
            font-size: 12px;
            color: $colorAccent1;
            line-height: 22px;

          }

          &:hover {
            background-color: $colorAccent1;

            i {
              color: $black;
            }
          }

          @media all and (max-width: 767px) { 
            display: none;
          }
          
        }

      }

    }

  }

  .footer-logo{
    position:relative;
    top:3px;
    margin-left:25px;
    max-width:120px;
  }

  .left{
    float: left;
  }
  .right{
    float: right;
  }

  @media all and (max-width: 1024px) {
    padding-left: 20px;
    padding-right: 20px;
  }

  @media (min-width: 768px) and (max-width: 991px) {
    .main-text, a {
      font-size: 14px;
    }
  }

  @media all and (max-width:900px){
    .footer-logo{
      display:block;
      margin:5px 0px;
    }
  }

  @media all and (max-width: 767px) {
    padding-top: 15px;
    padding-bottom: 15px;

    .main-text, a {
      font-size: 14px;
    }
  }

  @media all and (max-width: 599px) {

    padding: 15px 20px 10px;

  }

  @media all and (max-width: 499px) {
    .left{
      float: none;
      width: 100%;
      padding-bottom: 5px;
    }
    .right{
      float: none;
      width: 100%;
    }
    .main-text{
      width: 100%;
      text-align: center;

      &.footer-nav a {
        display: block;
        margin: 0;
      }
    }

    .footer-logo{
      margin:5px  auto 15px auto;
    }
  }

}