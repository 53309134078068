.bloc-location {
  padding: 70px 0px 0px;
  text-align: center;
  background: $white;
  position: relative;

  .max-width {
    width: 100%;
    max-width: 1000px!important;
    margin: auto;
  }

  .content {
    border: 1px solid $white;
    overflow: hidden;
    cursor: pointer;
    transition: all .3s linear;
    position: relative;
    display: block;

      &:hover img.background {
        transform: scale(1.1);
      }

      img {
        display: block;
        cursor: pointer;
        transition: all .3s linear;
        width: 100%;
        max-width: 100%;
      }

  }

  .title2{
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    color: $colorAccent1;
  }

  .title3{
    padding: 0;
    color: $black;
    font-size: 36px;
    margin-bottom: 20px;
  }

  .main-text{
    color: $black;
    text-align: center;
    margin-bottom: 50px;
  }

  .logo {
    position: absolute;
    width: 100%;
    padding: 0 40px;
    transform: translate(0,-50%);
    left: 0;
    top: 50%;
    z-index: 5;
    
    img {
      width: 100%;
      max-width: 240px;
      margin: auto;
    }

  }

  @media all and (max-width: 1024px) {
    padding: 60px 0px 0px;

    .title-section {
      padding: 0 20px; 
    }
  }

  @media all and (max-width: 767px) {
    .col.one-third {
      width: 50%;
      float: left;
    }

    .title3{
      font-size: 28px;
      line-height: 34px;
    }
  }

  @media all and (max-width: 400px) {
    .col.one-third {
      width: 100%;
      float: none;
    }
    .content {
      border-left: 0;
      border-right: 0;
    }
  }


}