
.bloc-quoteCta{
  position: relative;
  padding: 6vw 50px;
  background: $light;
  text-align: center;
  background-image: url('../images/background-cta.jpg');
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;

  .max-width {
    max-width: 1000px;
    margin: auto;
  }

  .title2{
    text-transform: none;
    margin-bottom: 20px;
    padding: 0;
    font-size: 20px;
    font-weight: 400;
    color: $colorAccent1;
  }

  .title3{
    padding: 0;
    color: $white;
    font-size: 36px;
    margin-bottom: 20px;
  }

  p {
    color: $white;
    font-size: 20px;
    font-weight: 400;
    font-family: $fontTitle;
    margin-bottom: 40px;
  }

  .site-btn {
    color: $black;

    &:hover {
      background: rgba(0,0,0,.25);
      color: $colorAccent1;
    }
  }

  @media all and (max-width: 1024px) {
    padding: 60px 20px;

  }

  @media all and (max-width: 767px) {
    .title3 {
      font-size: 32px;
    }

    p {
      font-size: 18px;
    }
    
  }

}