/*
 *    Main navigation
 *    ---------------
 */

.main-nav {
  margin-top: -70px;
  width: 100%;
  position: absolute;
  background-color: rgba(0,0,0,.85);
  padding: 22px 60px;
  color: $colorAccent1;
   z-index: 15;
  transition: all 0.2s ease-in-out;

  @extend %clearfix;

  @media all and (max-width: 767px) {
    padding: 22px 35px;

  }

  .nav-logo {
    float: left;
    width: auto;
    height: auto;
    display: none;

    @media all and (max-width: 767px) {
      width: 100%;
      text-align: center;
      margin-bottom: 20px;
      display: block;
      float: none;

      img {
        max-width: 100%;
        width: 120px;
      }

    }

  }

  #navbar {
    @extend %clearfix;

    .navbar-contain {

      .nav-content{
        text-align: center;

        a {
          margin-left: 20px;
          text-transform: uppercase;
          font-weight: 400;
          font-size: 16px;
          font-family: $fontTitle;
          color: $white;
          letter-spacing: 0.05em;
          position: relative;


          &.active {
            color: $colorAccent1;
          }

          &:before, &:after {
            position: absolute;
            top: 50%;
            left: 50%;
            width: 40px;
            height: 40px;
            border: 1px solid rgba(255,255,255,0.2);
            border-radius: 50%;
            content: '';
            opacity: 0;
            transition: transform 0.3s, opacity 0.3s;
            transform: translateX(-50%) translateY(-50%) scale(0.2);
          }

          &:after {
            width: 30px;
            height: 30px;
            border-width: 6px;
            background-color: rgba(255,255,255,0.2);
            transform: translateX(-50%) translateY(-50%) scale(0.8);
            border: 0;
          }

          &:hover::before, 
          &:hover::after, 
          &:focus::before, 
          &:focus::after {
            opacity: 1;
            transform: translateX(-50%) translateY(-50%) scale(1);
          }

          @media all and (max-width: 767px) {
            display: block;
            margin: auto;
            margin-bottom: 15px;

          }

          @media (min-width: 768px) and (max-width: 991px) {
            margin-left: 15px;
          }

        }

      }

    }

    @media all and (max-width: 767px) {
      position: fixed;
      top: 0;
      right: 0;
      bottom: 0;
      left: auto;
      transform: translate( 100%, 0);
      padding-top: 60px;
      width: 45%;
      height: auto;
      min-width: 375px;
      background-color: rgba(0, 0, 0, .85);
      z-index: 99;
      transition: all ease 0.33s;

  
      &.active{
        transform: translate( 0, 0);
        padding-top: 60px;
      }

    }

    @media all and (max-width: 400px) {
      width: 100%;
      min-width: inherit;
    }

  }

  .navbar-fixed & {
    position: fixed;
    top: 0;
    left: 0;
    margin-top: 0;
    padding-top: 10px;
    padding-bottom: 10px;

    @media all and (max-width: 767px) {
      border-bottom: 1px solid $white;
      padding-top: 35px;
      padding-bottom: 35px;
    }

    @media (min-width: 768px) and (max-width: 991px) {
      padding: 20px;
    }

    .nav-logo {
      display: block;

      img {
        max-width: 100%;
        width: 50px;
        display: block;

        @media all and (max-width: 767px) {
          display: inline-block;
          width: 120px;
        }

      }

    }

    .nav-content {
      float: right;
      margin-top: 12px;
      margin-left: 10px;

      @media all and (max-width: 767px) {
        float: none;
        margin: 0;
      }
    }

  } 

  #menu-mobile {
    width: 100%;
    display: none;

    .navbar-fixed & {
      position: fixed;
      top: 0;
      left: 0;
      z-index: 99999;
    }

    @media (max-width: 767px) {
      display: block;
    }
    .menu {
      color: $colorAccent1;
      margin-top: 5px;
      display: block;
      float: right;

      @media (max-width: 767px) {
        .navbar-fixed & {
          padding-top: 12px;
          padding-right: 35px;
          margin-top: 15px;
        }
      }

      .mobile-bar {
          display: block;
          width: 25px;
          height: 2px;
          margin-bottom: 5px;
          background-color: $colorAccent1;
      }

      .mobile-close {
        display: none;
        color: $black;
        font-size: 20px;
        z-index: 999999;
        position: fixed;
        top: 10px;
        right: 20px;
        font-weight: 400;
        width: 30px;
        height: 30px;
        border-radius: 50%;
        background-color: $colorAccent1;
        text-align: center;
        line-height: 27px;
      }


      &.active{
        .mobile-bar {
          opacity: 0;
        }

        &.logo {
          opacity: 0;
        }

        .mobile-close {
          display: block;
        }

      }

    }

    .logo {
        display: none;
        margin-left: 35px;
        z-index: 1;
        margin-top: -2px;

        img {
          width: 50px;
        }
        
        @media (max-width: 767px) {
        .navbar-fixed & {
          display: inline-block;
          float: left;
          width: auto;
          margin-top: -2px;
        }
      }
    }

  }

}
