/**
*
* Global
*
**/

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body,
button {
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-smoothing: antialiased;
}

body {
  font-family: $fontText;
  font-size:16px;
  -webkit-font-smoothing: antialiased; /* Fix for webkit rendering */
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  line-height: 1.6250em; /* 26px */
  font-weight: 300; // to thicken it a bit, we need to remove font-smoothing
  color: #222;
}


h1, h2, h3, h4, h5, h6{
  padding: 0;
  margin: 0;
}


/* ==========================================================================
 Outdated Browser prompt
 ========================================================================== */

.outdatedbrowser{
  z-index: 999999;
  padding: 20px 35px;
  background: white;
  color: #444;
  font-size: 19px;
  line-height: 29px;
  margin: 0;
  text-align: center;
  position: fixed;
  left: 0;
  bottom: 0;
  border-top: 2px solid red;
  border-bottom: 2px solid red;
  width: 100%;
  height: auto;
}
.lt-ie9 .outdatedbrowser{
  position: relative;
}


/* ==========================================================================
 Browser Selection color
 ========================================================================== */
/*
 * Remove text-shadow in selection highlight: h5bp.com/i
 * These selection rule sets have to be separate.
 * Customize the background color to match your design.
 */

::-moz-selection {
  background: #333;
  color: #fff;
  text-shadow: none;
}

::selection {
  background: #333;
  color: #fff;
  text-shadow: none;

}

a{
  color: inherit;
  text-decoration: none;
}

/* j.mp/webkit-tap-highlight-color */
a:link { -webkit-tap-highlight-color: #333; }



